import React, { useEffect } from "react"
import Img from "gatsby-image"
import { Box, Grid, Heading } from "@theme-ui/components"
import { useInView } from "react-intersection-observer"
import storyTimeline from "../images/story-timeline.svg"
import { motion, useAnimation } from "framer-motion"

const StoryBlock = ({ milestones }) => {
  // const stageCanvasRef = useRef(null)

  return (
    <Box>
      {milestones.map((milestone, index) => (
        <Milestone milestone={milestone} index={index} />
      ))}
    </Box>
  )
}

const Milestone = ({ milestone, index }) => {
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0.4,
  })
  const controls = useAnimation()
  useEffect(() => {
    if (inView === true) {
      controls.start({
        opacity: 1,
        y: 0,
        x: 0,
        transition: { duration: 0.7 },
      })
    }
  }, [inView, controls])

  return (
    <Grid
      key={milestone.id}
      columns={[1, 1, 2]}
      gap={[32, 32, 128, 256]}
      sx={{
        paddingBottom: 6,
        paddingLeft: [6, 6, 0],
        backgroundImage: `url(${storyTimeline})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: ["top left", "top left", "top center"],
        minHeight: "10rem",
      }}
      ref={ref}
    >
      <Box sx={{ order: [1, 1, index % 2 ? 1 : 2] }}>
        <motion.div as={Box} initial={{ opacity: 0, y: 0, x:20 }} animate={controls}>
          <Heading sx={{ fontSize: 6, marginBottom: 4, color: "primary" }}>
            {milestone.date}
          </Heading>
          <Box dangerouslySetInnerHTML={{ __html: milestone.text }} />
        </motion.div>
      </Box>
      <Box sx={{ order: [2, 2, index % 2 ? 2 : 1] }}>
        <motion.div as={Box} initial={{ opacity: 0, y: 40 }} animate={controls}>
          {milestone.image && <Img fluid={milestone.image.fluid} />}
        </motion.div>
      </Box>
    </Grid>
  )
}

export default StoryBlock
