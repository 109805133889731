import React, { useEffect, useState, useRef } from "react"
import { setConfiguration } from "react-grid-system"
import styled from "styled-components"
import { Row } from "react-grid-system"
import { Heading } from "./typography"
import { Box as ThemeBox } from "theme-ui"
import customBorder from "../images/custom-border.svg"
import { Container } from "theme-ui"
import RichContentTextBlock from "./richContentTextBlock"
import ScrollTrigger from "react-scroll-trigger"

const GantTable = ({ title, table }) => {
  const [sticky, setSticky] = useState(false)
  const [firstRowHeight, setFirstRowHeight] = useState(0)
  const firstRowRef = useRef(null)
  const [animationTriggered, setAnimationTriggered] = useState(false)

  useEffect(() => {
    const checkStickiness = () => {
      const firstRow = firstRowRef.current.querySelector("tbody tr")
      if (!firstRow) return
      setSticky(window.scrollY >= firstRow.getBoundingClientRect().top)
      setFirstRowHeight(firstRow.offsetHeight)
    }

    window.addEventListener("scroll", checkStickiness)
    return () => window.removeEventListener("scroll", checkStickiness)
  }, [])

  useEffect(() => {
    const images = firstRowRef.current.querySelectorAll("img")
    images.forEach((img, index) => {
      img.style.animationDelay = `${index * 0.5}s` // Change '0.5s' to the delay you want between each animation
    })
  }, [])

  const [animationState, setAnimationState] = useState(false)

  return (
    <Container>
      <Box>
        <Heading>{title}</Heading>
      </Box>
      <div className="scrollable-table">
        <ScrollTrigger
          onEnter={() => {
            if (!animationTriggered) {
              setAnimationState(true)
              setAnimationTriggered(true)
            }
          }}
        >
          <ThemeBox
            ref={firstRowRef}
            sx={{
              td: {
                fontSize: [0, 1, 1, 2],
                color: "black",
                fontWeight: ["unset", "unset", "unsent", "bold"],
                textAlign: "center",
                minWidth: "30px",
                px: "unset",
              },
              "tbody tr td:first-child": {
                backgroundColor: "white",
                width: "25%", // Set the background color to white
              },

              h3: {
                ml: 1,
              },
              p: {
                backgroundColor: "white",
                textAlign: "start",
                color: "black",
                ml: 2,
              },

              img: {
                zIndex: -1,
                display: "block",
                width: "100%",
                height: "50px",
                position: "relative",
                opacity: 0, // Set initial opacity to 0
                animation: animationState
                  ? "slide-in 2s ease-in-out forwards, fade-in 2s ease-in-out forwards"
                  : "none", // Add 'forwards' to both animations
                "@keyframes slide-in": {
                  "0%": {
                    transform: "translateX(-100%)",
                  },
                  "100%": {
                    transform: "translateX(0)",
                  },
                },
                "@keyframes fade-in": {
                  "0%": {
                    opacity: 0,
                  },
                  "100%": {
                    opacity: 1,
                  },
                },
              },

              "thead tr:nth-child(1) td": {
                position: sticky ? "sticky" : "relative",
                top: 0,
                zIndex: sticky ? 2 : 0,
                backgroundColor: "white", // Set a solid background color
                boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.15)",
                overflow: "hidden", // Add a box shadow
              },
              "thead tr:nth-child(2) td": {
                position: sticky ? "sticky" : "relative",
                // top: `${firstRowHeight}px`,
                top: "50px",
                zIndex: sticky ? 2 : 0,
                backgroundColor: "white", // Set a solid background color
                boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.15)", // Add a box shadow
              },
            }}
            dangerouslySetInnerHTML={{ __html: table }}
          />
        </ScrollTrigger>
      </div>
    </Container>
  )
}

const Wrapper = styled.div``

const CustomRow = styled(Row)`
  /* padding-top: 3rem; */
  padding-bottom: 3rem;
`

const Box = styled.div`
  width: 100%;
  margin-top: 2rem;
  padding-top: 2rem;
  background-image: url(${customBorder});
  background-repeat: no-repeat;
  & > * {
    margin-bottom: 2rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`
export default GantTable
