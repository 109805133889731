import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import TextBlock from "../components/textBlock"
import ImageBlockLegacy from "../components/imageBlockLegacy"
import CallToAction from "../components/callToAction"
import HeroHeader from "../components/heroHeader"
import PageHeader from "../components/pageHeader"
import GalleryBlock from "../components/galleryBlock"
import StoryBlock from "../components/storyBlock"
import AccordionBlock from "../components/accordionBlock"
import HeroBlock from "../components/heroBlock"
import DocumentsBlock from "../components/documentsBlock"
import { Box, Container } from "@theme-ui/components"
import { getPagePath } from "../components/pathUtils"
import { HelmetDatoCms } from "gatsby-source-datocms"
import GantTable from "../components/gantTable"

const Page = ({ data: { page }, pageContext, location }) => {
  const lang = pageContext.locale
  const i18nPaths = page._allSlugLocales.map(locale => {
    return {
      locale: locale.locale,
      value: getPagePath(page, locale.locale),
    }
  })

  return (
    <Layout
      lang={lang}
      title={page.title}
      location={location}
      i18nPaths={i18nPaths}
    >
      <HelmetDatoCms seo={page.seoMetaTags}>
        <html lang={lang} />
      </HelmetDatoCms>
      {page.heroImage ? (
        <HeroHeader
          title={page.title}
          subtitle={page.subtitle}
          heroImage={page.heroImage}
          inDepth={page.inDepth}
        />
      ) : (
        <PageHeader
          title={page.title}
          subtitle={page.subtitle}
          heroImage={page.heroImage}
          inDepth={page.inDepth}
        />
      )}
      <Wrapper>
        {page.content.map((section, index) => (
          <section key={section.id}>
            {section.model.apiKey === "gant_table" && (
              <GantTable title={section.title} table={section.table} />
            )}
            {section.model.apiKey === "documents_block" && (
              <DocumentsBlock documents={section.documents} fullwidth />
            )}
            {section.model.apiKey === "accordion_block" && (
              <Container>
                <AccordionBlock paragraphs={section.paragraphs} />
              </Container>
            )}
            {section.model.apiKey === "story_block" && (
              <Container variant={"small"}>
                <StoryBlock milestones={section.milestones} />
              </Container>
            )}
            {section.model.apiKey === "gallery_block" && (
              <GalleryBlock
                text={section.text}
                gallery={section.gallery}
                page={true}
              />
            )}
            {section.model.apiKey === "text_block" && (
              <Box
                sx={{
                  backgroundColor: section.highlight ? "#e6f2ec" : null,
                  boxShadow: section.highlight ? "0px 100px 0px #e6f2ec" : null,
                  mt: section.highlight ? [3, 7] : null,
                }}
              >
                <Container>
                  <Box>
                    <TextBlock
                      title={section.title}
                      text={section.text}
                      highlight={section.highlight}
                    />
                  </Box>
                </Container>
              </Box>
            )}
            {section.model.apiKey === "hero_block" && (
              <Container>
                <HeroBlock
                  text={section.text}
                  title={section.title}
                  image={section.image}
                  gallery={section.gallery}
                  documents={section.documents}
                  alternate={section.alternate}
                />
              </Container>
            )}
            {section.model.apiKey === "image_block" && (
              <ImageBlockLegacy
                link={section.link}
                title={section.title}
                text={section.text}
                image={section.image}
                highlight={section.highlight}
                alignment={section.rightAlignment}
                negativeMarginTop={section.negativeMarginTop}
              />
            )}
          </section>
        ))}
      </Wrapper>
      {page.callToAction && (
        <CallToAction
          title={page.callToAction.title}
          backgroundImage={page.callToAction.backgroundImage}
          slug={page.callToAction.slug}
          name={page.callToAction.name}
        />
      )}
    </Layout>
  )
}
const Wrapper = styled.div`
  padding-top: 6rem;
  padding-bottom: 2rem;
  @media screen and (max-width: 768px) {
    padding-top: 2rem;
  }
`

export default Page

export const query = graphql`
  query PageQuery($slug: String!, $locale: String!) {
    page: datoCmsPage(slug: { eq: $slug }, locale: { eq: $locale }) {
      ...AllSlugLocales
      slug
      title
      root
      subtitle
      heroImage {
        fluid(maxWidth: 1920, imgixParams: { fm: "jpg" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      id
      locale
      inDepth
      treeParent {
        slug
        root
        ...AllSlugLocales
      }
      content {
        ... on DatoCmsGantTable {
          title
          id
          table
          model {
            apiKey
          }
        }
        ... on DatoCmsHeroBlock {
          ...HeroBlockDetails
        }
        ... on DatoCmsAccordionBlock {
          id
          model {
            apiKey
          }
          paragraphs {
            id
            title
            text
            image {
              fluid(maxWidth: 720, imgixParams: { fm: "jpg" }) {
                ...GatsbyDatoCmsFluid
              }
            }
          }
        }
        ... on DatoCmsGalleryBlock {
          text
          gallery {
            fluid(maxWidth: 720, imgixParams: { fm: "jpg" }) {
              ...GatsbyDatoCmsFluid
            }
          }
          id
          model {
            apiKey
          }
        }
        ... on DatoCmsStoryBlock {
          id
          model {
            apiKey
          }
          milestones {
            id
            date
            text
            image {
              fluid(maxWidth: 720, imgixParams: { fm: "jpg" }) {
                ...GatsbyDatoCmsFluid
              }
            }
          }
        }
        ... on DatoCmsTextBlock {
          title
          text
          highlight
          id
          model {
            apiKey
          }
        }
        ... on DatoCmsDocumentsBlock {
          id
          documents {
            id
            title
            documents {
              originalId
              url
              title
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsImageBlock {
          title
          text
          image {
            fluid(maxWidth: 520, imgixParams: { fm: "jpg" }) {
              ...GatsbyDatoCmsFluid
            }
          }
          highlight
          rightAlignment
          negativeMarginTop
          id
          link {
            id
            name
            locale
            page {
              slug
              root
              ...AllSlugLocales
              treeParent {
                slug
                root
                ...AllSlugLocales
                treeParent {
                  slug
                  root
                  ...AllSlugLocales
                }
              }
            }
          }
          model {
            apiKey
          }
        }
      }
      callToAction {
        title
        backgroundImage {
          fluid(maxWidth: 1920, imgixParams: { fm: "jpg" }) {
            ...GatsbyDatoCmsFluid
          }
        }
        slug
        name
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }

  fragment AllSlugLocales on DatoCmsPage {
    _allSlugLocales {
      value
      locale
    }
  }

  fragment HeroBlockDetails on DatoCmsHeroBlock {
    title
    text
    alternate
    gallery {
      originalId
      fluid(maxWidth: 520, imgixParams: { fm: "jpg" }) {
        ...GatsbyDatoCmsFluid
      }
      title
    }
    documents {
      originalId
      url
      title
    }
    id
    model {
      apiKey
    }
  }
`
