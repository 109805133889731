import React, { useState } from "react"
import Img from "gatsby-image"
import { Box, Grid, Flex } from "@theme-ui/components"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"
import RichContentTextBlock from "./richContentTextBlock"

const AccordionBlock = ({ paragraphs }) => {
  const [expandedId, setExpandedId] = useState(paragraphs[0].id)
  function isExpanded(id) {
    // console.log(id, expandedId)
    return id === expandedId
  }
  return (
    <Box>
      <Accordion
        preExpanded={[paragraphs[0].id]}
        onChange={uuid => setExpandedId(uuid[0])}
      >
        {paragraphs.map((paragraph, index) => (
          <AccordionItem key={paragraph.id} uuid={paragraph.id}>
            <Box as={AccordionItemHeading}>
              <Box
                as={AccordionItemButton}
                sx={{
                  paddingX: [2, 6],
                  fontSize: [3, 4],
                  backgroundColor: "primary",
                  color: "light",
                  cursor: "pointer",
                }}
              >
                <Flex
                  sx={{
                    paddingX: 3,
                    paddingY: 2,
                    borderBottom:
                      isExpanded(paragraph.id) ||
                      index === paragraphs.length - 1
                        ? "none"
                        : "1px solid #ffffff",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box as="p">{paragraph.title}</Box>
                  <Box sx={{ fontSize: 6 }}>
                    {isExpanded(paragraph.id) ? "-" : "+"}
                  </Box>
                </Flex>
              </Box>
            </Box>
            <Box
              as={AccordionItemPanel}
              sx={{
                paddingX: [2, 6],
                paddingY: [4],
                backgroundColor: "#D9EBE2",
              }}
            >
              <Box sx={{ paddingX: 3 }}>
                <Grid columns={[1, 1, paragraph.image ? 2 : 1]} gap={64}>
                  <Box>
                    <RichContentTextBlock html={paragraph.text} />
                  </Box>
                  {paragraph.image && (
                    <Box>
                      <Img fluid={paragraph.image.fluid} />
                    </Box>
                  )}
                </Grid>
              </Box>
            </Box>
          </AccordionItem>
        ))}
      </Accordion>
    </Box>
  )
}

export default AccordionBlock
